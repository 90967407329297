import React from 'react'
import clsx from 'clsx'
import { graphql, Link } from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import useForm from 'react-hook-form'

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Slide from '@material-ui/core/Slide'
import Snackbar from '@material-ui/core/Snackbar'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { TransitionProps } from '@material-ui/core/transitions'

import SmallWindow from './../../components/SmallWindow'
import SEO from './../../components/SEO'

type SubscribePageProps = {}

const useStyles = makeStyles((theme: Theme) => createStyles({
  '@global': {
    body: {
      backgroundColor: theme.palette.background.white
    },
  },
  form: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
  textField: {},
}))

function SlideTransition(props: TransitionProps) {
  return <Slide {...props} direction="up" />
}

const Subscribe: React.FunctionComponent<SubscribePageProps> = () => {
  const classes = useStyles()
  const {register, handleSubmit, errors} = useForm()

  const [alert, setAlert] = React.useState({
    open: false,
    message: ''
  })

  const [form, setForm] = React.useState({
    hasSubmitted: false,
    hasError: false
  })

  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false
    })
  }

  const onSubmit = (values): Promise => {
    const {email} = values
    return addToMailchimp(email).then((res) => {
      let {result, msg} = res
      if (result === 'error') {
        setAlert({
          ...alert,
          open: false,
          message: msg.includes('already subscribed')
            ? `${email} is already subscribed to our list!`
            : `${msg}`
        })

        return setForm({
          ...form,
          hasError: true
        })
      }

      setAlert({
        ...alert,
        open: false,
        message: `${msg}`
      })

      return setForm({
        ...form,
        hasSubmitted: true,
        hasError: false
      })
    })
  }

  return (
    <SmallWindow>
      <SEO desc="Subscribe" title="Subscribe to the launch | Landing.com" />
      <Grid container spacing={5}>
        <Grid item sm={12} md={12}>
          <Box marginBottom={3}>
            <Typography component="h2" variant="h2">
              Nobody needs a website.<br />Everybody needs a landing.
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography variant="subtitle1">
              Build simple websites in record time and stop paying for features you don't use.
            </Typography>
          </Box>
          <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>

              {(form.hasSubmitted || form.hasError) &&
                <Box marginBottom={2}>
                  <Alert severity={form.hasError ? 'error' : 'success'}>
                    {alert.message}
                  </Alert>
                </Box>
              }
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="email"
                  className={classes.textField}
                  disabled={form.hasSubmitted}
                  error={!!errors.email || !!form.hasError}
                  fullWidth={true}
                  helperText={!!errors.email ? "This should be a valid email address." : null}
                  id="email"
                  inputRef={register({required: true, pattern: /^\S+@\S+$/i})}
                  label="Enter your email address"
                  name="email"
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  className={classes.submit}
                  disabled={form.hasSubmitted}
                  fullWidth={true}
                  type="submit"
                  variant="contained"
                >
                  Join the launch
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Snackbar
        autoHideDuration={3000}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{alert.message}</span>}
        onClose={handleAlertClose}
        open={alert.open}
        TransitionComponent={SlideTransition}
      />
    </SmallWindow>
  )
}

export default Subscribe
